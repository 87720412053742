import create from 'zustand';

export const useAuthStore = create((set) => ({
  auth: {
    username: '',
    role: '',   
    active: false
  },
  setAuthData: ({ username, role, active }) => set((state) => ({
    auth: { ...state.auth, username, role, active }
  }))
}));
