import React, { useState } from 'react';
import { notification } from 'antd'; // Import Ant Design notification
import imeilogo from '../../assets/report/gearvu-logo.png';
import './ImeiFinder.css';
import errorSound from '../../assets/sounds/error-sound.mp3'; // Ensure you have an error sound file here

function ImeiFinder() {
  const [imei, setImei] = useState('');

  const playErrorSound = () => {
    const audio = new Audio(errorSound);
    audio.play();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validImei1 = '350776596493651';
    const validImei2 = '353984101551164';

    if (imei === validImei1 || imei === validImei2) {
      // Redirect to the report URL for the valid IMEI
      window.open(`https://www.dymax.a2hosted.com/report/${imei}`, '_blank');
    } else {
      playErrorSound();
      notification.error({
        message: 'No IMEI number record found!',
        description: 'No IMEI number record found! Please enter a correct IMEI number.',
        placement: 'topRight',
        duration: 5,
        style: { fontSize: '16px', color: '#FF4D4F' }
      });
    }
  };

  const handleInputChange = (e) => {
    // Only allow numbers
    const numericValue = e.target.value.replace(/\D/g, '');
    setImei(numericValue);
  };

  return (
    <div className="App">
      <div className="imei-finder">
        <div className="container">
          <div className="row np">
            <div className="col-md-12">
              <img src={imeilogo} className="img-fluid imei-logo" alt="Gearvu Logo" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h2><span>Device Inspection</span> Report Lookup</h2>
              <p>
                Enter your device’s IMEI number below to access your inspection report. <br />
                Once submitted, if a report is found, it will open in a new tab for easy viewing.
              </p>
              <form onSubmit={handleSubmit}>
                <div>
                  <span>IMEI NUMBER</span>
                  <input 
                    size="1" 
                    type="text" 
                    name="imei" 
                    id="imei" 
                    value={imei} 
                    onChange={handleInputChange}
                  />
                </div>
                <br />
                <button type="submit">View Report</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImeiFinder;
