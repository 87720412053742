import './Dashboard.css';
import logo from './assets/img/logo.png';
import icon1 from './assets/img/icon-1.png';
import icon2 from './assets/img/icon-2.png';
import icon3 from './assets/img/icon-3.png';
import icon4 from './assets/img/icon-4.png';
import icon5 from './assets/img/icon-5.png';
import icon6 from './assets/img/icon-6.png';
import dashboard from './assets/img/dashboard.png';
import search from './assets/img/search.png';

import { useNavigate } from 'react-router-dom';
import { FloatButton, Checkbox } from 'antd';
import { FilePdfOutlined, FileExcelOutlined, ExportOutlined } from '@ant-design/icons';
import { useState } from 'react';
import jsPDF from 'jspdf';
import axios from 'axios';

function Dashboard() {
  const navigate = useNavigate();

  // State to manage checkbox status
  const [checkedItems, setCheckedItems] = useState({
    '1222615741': false,
    '53618969': false,
  });

  const reports = [
    {
      reportId: '1222615741',
      imei: '353984101551164',
      reportDate: '10/27/24',
      model: 'Apple iPhone 11',
      status: 'Completed',
    },
    {
      reportId: '53618969',
      imei: '350776596493651',
      reportDate: '10/02/20',
      model: 'Apple iPhone 13',
      status: 'Not Completed',
    },
  ];

  // Logout handler function
  function userLogout() {
    localStorage.removeItem('token');
    navigate('/');
  }

  // Profile navigation handler
  function goToProfile() {
    navigate('/profile');
  }

  // Function to open report details in a new tab
  function openReportDetails(imei) {
    window.open(`/report/${imei}`, '_blank');
  }

  // IMEI Finder Navigation
  function openImeiFinder() {
    window.open('/imei-finder', '_blank');
  }

  const handleCheckboxChange = (reportId) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [reportId]: !prevState[reportId],
    }));
  };

   // Function to fetch and format report details by IMEI
  const fetchReportDetails = async (imei) => {
    try {
      const response = await axios.get(`/api/reports/${imei}`);
      const { report, extra } = response.data.data;

      // Extract parts replaced data
      const partsReplaced = extra.json.PartsReplaced?.iOS || {};
      const batteryInfo = extra.json.DeviceInfo?.BatteryInfo[0] || {};

      // Format required data
      return {
        reportId: report.reportid,
        startTime: report.starttime,
        finishTime: report.finishtime,
        imei: report.IMEI,
        serialNumber: report.serialnumber,
        manufacturer: report.Manufacturer,
        model: report.Model,
        os: report.os,
        operator: report.operator,
        reportStatus: report.reportstatus,
        reportResult: report.reportresult,
        reportType: report.reporttype,
        action: report.action,

        // Device Info
        countryOrigin: extra.json.DeviceInfo?.CountryOrigin || 'N/A',
        cpu: extra.json.DeviceInfo?.CpuName || 'N/A',
        ram: extra.json.DeviceInfo?.RAM || 'N/A',
        memorySize: extra.json.DeviceInfo?.HandsetMemorySize || 'N/A',
        batteryHealth: batteryInfo.BatteryHealth || 'N/A',
        batteryCycleCount: batteryInfo.BatteryCycleCount || 'N/A',

        // Wipe Info
        wipeMethod: extra.json.DataWipeInfo?.WipeMethod || 'N/A',
        wipeStatus: extra.json.DataWipeInfo?.Status || 'N/A',

        // Parts Replaced
        batteryStatus: partsReplaced.Battery?.result || 'N/A',
        lcdStatus: partsReplaced.LCD?.result || 'N/A',
        mainBoardStatus: partsReplaced.MainBoard?.result || 'N/A',
      };
    } catch (error) {
      console.error(`Failed to fetch report details for IMEI ${imei}:`, error);
      return null;
    }
  };

  // Export as PDF handler
  const exportAsPDF = async () => {
    const selectedReports = reports.filter((report) => checkedItems[report.reportId]);
    if (selectedReports.length === 0) {
      alert('Please select at least one record to export.');
      return;
    }

    const doc = new jsPDF();
    doc.text('Trade-in Device Detailed Reports', 10, 10);
    let yPos = 20;

    for (const report of selectedReports) {
      const reportData = await fetchReportDetails(report.imei);
      if (reportData) {
        yPos += 10;
        doc.text(`Report ID: ${reportData.reportId}`, 10, yPos);
        doc.text(`IMEI: ${reportData.imei}`, 10, yPos + 10);
        doc.text(`Model: ${reportData.model}`, 10, yPos + 20);
        doc.text(`Manufacturer: ${reportData.manufacturer}`, 10, yPos + 30);
        doc.text(`OS: ${reportData.os}`, 10, yPos + 40);
        doc.text(`Report Result: ${reportData.reportResult}`, 10, yPos + 50);
        doc.text(`Battery Health: ${reportData.batteryHealth}`, 10, yPos + 60);
        doc.text(`RAM: ${reportData.ram}`, 10, yPos + 70);
        doc.text(`Wipe Method: ${reportData.wipeMethod}`, 10, yPos + 80);
        doc.text(`LCD Status: ${reportData.lcdStatus}`, 10, yPos + 90);
        yPos += 100;
      }
    }

    doc.save('detailed-reports.pdf');
  };

  // Export as CSV handler
  const exportAsCSV = async () => {
    const selectedReports = reports.filter((report) => checkedItems[report.reportId]);
    if (selectedReports.length === 0) {
      alert('Please select at least one record to export.');
      return;
    }

    const csvRows = [
      [
        'Report ID',
        'IMEI',
        'Model',
        'Manufacturer',
        'OS',
        'Report Result',
        'Battery Health',
        'RAM',
        'Wipe Method',
        'LCD Status',
      ],
    ];

    for (const report of selectedReports) {
      const reportData = await fetchReportDetails(report.imei);
      if (reportData) {
        csvRows.push([
          reportData.reportId,
          reportData.imei,
          reportData.model,
          reportData.manufacturer,
          reportData.os,
          reportData.reportResult,
          reportData.batteryHealth,
          reportData.ram,
          reportData.wipeMethod,
          reportData.lcdStatus,
        ]);
      }
    }

    const csvString = csvRows.map((e) => e.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'detailed-reports.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="App">
      <div className="dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 sidebar">
              <img src={logo} className="img-fluid logo" alt="Logo" />
              <div className="navigation">
                <div className="nav-list active"><a href="#"><img src={icon1} className="img-fluid" alt="Dashboard" /> Dashboard</a></div>
                <div className="nav-list"><a href="#" onClick={openImeiFinder}><img src={icon2} className="img-fluid" alt="Kiosk Status" /> IMEI Finder</a></div>
                <div className="nav-list"><a href="#"><img src={icon3} className="img-fluid" alt="Reports" /> Trade-in Device Reports</a></div>
                <div className="nav-list"><a href="#"><img src={icon4} className="img-fluid" alt="Protection Plan" /> Protection Plan</a></div>
                <div className="nav-list"><a href="#"><img src={icon5} className="img-fluid" alt="Transactions" /> Transactions</a></div>
                <div className="nav-list"><a href="#"><img src={icon6} className="img-fluid" alt="Customers" /> Customers</a></div>
              </div>
            </div>
            <div className="col-md-9 main">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"><img src={search} alt="Search" /></span>
                </div>
                <input type="text" className="form-control" placeholder="Searching" />
              </div>

              <div className="buttons">
                <button className="red" onClick={goToProfile}>Account</button>
                <button className="black" onClick={userLogout}>Logout</button>
              </div>
              <br />
              <img src={dashboard} className="img-fluid dash-img" alt="Dashboard" />
              <section className="reports">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <table className="table table-striped table-bordered align-items-center">
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th>Report ID</th>
                            <th>IMEI</th>
                            <th>Report Date</th>
                            <th>Device Model</th>
                            <th>Status</th>
                            <th>Details</th>
                       
                          </tr>
                        </thead>
                        <tbody>
                          {reports.map(report => (
                            <tr key={report.reportId}>
                              <td>
                                <Checkbox
                                  checked={checkedItems[report.reportId]}
                                  onChange={() => handleCheckboxChange(report.reportId)}
                                />
                              </td>
                              <td>{report.reportId}</td>
                              <td>{report.imei}</td>
                              <td>{report.reportDate}</td>
                              <td>{report.model}</td>
                              <td>{report.status}</td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => openReportDetails(report.imei)}
                                >
                                  Show Details
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button className="black" onClick={exportAsPDF}>Export as PDF</button>
                      <button className="black" onClick={exportAsCSV}>Export as CSV</button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
