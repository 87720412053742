import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import avatar from '../assets/profile.png';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import { usernameValidate, passwordValidate } from '../helper/validate';
import { verifyPassword } from '../helper/helper';
import { useAuthStore } from '../store/store'; // Import the store
import styles from '../styles/Username.module.css';
import newcss from './Login.css';
import logo from '../assets/report/gearvu-logo.png';


export default function Login() {
  const navigate = useNavigate();
  const setAuthData = useAuthStore((state) => state.setAuthData);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validate: (values) => {
      // Validation logic here
    },
    onSubmit: async (values) => {
      let loginPromise = verifyPassword({
        username: values.username,
        password: values.password,
      });

      toast.promise(loginPromise, {
        loading: 'Checking...',
        success: <b>Login Successful!</b>,
        error: <b>Invalid Username or Password!</b>,
      });

      loginPromise.then((res) => {
        const { username, role, token } = res.data;
 
        // Save token in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);

        // Save username and role in store
        setAuthData({
          username,
          role,   
          active: true,
        });


        // Navigate to report page
        navigate('/dashboard');
      }).catch(err => {
        console.log("Login failed:", err);
      });
    }
  });

  return (
    <>
      <div className="container mx-auto">
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="App">
        <div className="login">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <img src={logo} className="img-fluid logo" />
                <h2>Welcome.</h2>
                <div className="social-icons">
                  <span className="social-icon">
                    <a className="icon-facebook-f" target="_blank">
                      {/* SVG icon here */}
                    </a>
                  </span>
                  <span className="social-icon">
                    <a className="icon-twitter" target="_blank">
                      {/* SVG icon here */}
                    </a>
                  </span>
                  <span className="social-icon">
                    <a className="icon-linkedin" target="_blank">
                      {/* SVG icon here */}
                    </a>
                  </span>
                </div>
                <p>This site is a private members site, by invitation only.</p>
              </div>
              <div className="col-md-5">
                <form className="py-1" onSubmit={formik.handleSubmit}>
                  <div className="profile flex justify-center py-4">
                    <img src={avatar} className={styles.profile_img} alt="avatar" />
                  </div>

                  <div className="textbox flex flex-col items-center gap-6">
                    <input
                      {...formik.getFieldProps('username')}
                      className={styles.textbox}
                      type="text"
                      placeholder="Username"
                      required
                    />
                    <input
                      {...formik.getFieldProps('password')}
                      className={styles.textbox}
                      type="password"
                      placeholder="Password"
                      required
                    />
                    <button className={styles.btn} type="submit">
                      Sign In
                    </button>
                  </div>

                  <div className="text-center py-4">
                    <span className="text-gray-500">
                      <Link className="text-red-500" to="/recovery">
                        Forgot your Password?
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
