import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


/** import all components */
import Username from './components/Username';
import Password from './components/Password';
import Register from './components/Register';
import Profile from './components/Profile';
import Recovery from './components/Recovery';
import Reset from './components/Reset';
import PageNotFound from './components/PageNotFound';
import Report from './components/Report/Report';
import Report2 from './components/Report/Report2';
import Report3 from './components/Report/Report3';

import { useAuthStore } from './store/store';



/** auth middleware */
import { AuthorizeUser, ProtectRoute } from './middleware/auth'
import { RoleBasedRoute } from './middleware/role'
import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import ImeiFinder from './components/ImeiFinder/ImeiFinder';

/** root routes */
const router = createBrowserRouter([
    {
        path : '/',
        element : <Login></Login>
    },
    {
        path : '/register',
        element : <Register></Register>
    },
    {
        path : '/password',
        element : <ProtectRoute><Password /></ProtectRoute>
    },
    {
        path : '/profile',
        element : <AuthorizeUser><Profile /></AuthorizeUser>
    },
    {
        path : '/recovery',
        element : <Recovery></Recovery>
    },
    {
        path : '/reset',
        element : <Reset></Reset>
    },
    {
        path : '*',
        element : <PageNotFound></PageNotFound>
    }, 
    // {
    //     path : '/report',
    //     element :<RoleBasedRoute allowedRoles={['admin']}> <Report /></RoleBasedRoute>
    // }, 
    {
        path : '/dashboard',
        element :<AuthorizeUser> <Dashboard /> </AuthorizeUser>
    }, 

    //Report Paths
    {
        path : '/report/353984101551164',
        element:<AuthorizeUser><Report2 /></AuthorizeUser> 
    },

    {
        path : '/report/350776596493651',
        element: <AuthorizeUser><Report3 /></AuthorizeUser>
    },

    //IMEI Finder
    {
        path : '/imei-finder',
        element: <ImeiFinder />
    },


])

export default function App() {
 return (
    <main>
        <RouterProvider router={router}></RouterProvider>
    </main>
  )
}
