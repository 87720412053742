import React, { useState, useRef, } from 'react';
import Magnifier from 'react-magnifier';
import logo from '../../assets/report/logo.png';
import qr from '../../assets/report/qr.png';
import device from '../../assets/report/device.png';
import network from '../../assets/report/network.png';
import health from '../../assets/report/health.png';
import img1 from '../../assets/report/img1.png';
import img2 from '../../assets/report/img2.png';
import a from '../../assets/report/a.png';
import globe from '../../assets/report/globe.png';
import mob from '../../assets/report/mob.png';
import ins from '../../assets/report/ins.png';
import lin from '../../assets/report/lin.png';
import fac from '../../assets/report/fac.png';
import pin from '../../assets/report/pin.png';
import goog from '../../assets/report/goog.png';
import avatar from '../../assets/profile.png';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


import './Report.css';

//Device Images
import Back from '../../assets/report/device/Back.jpg';
import Behind from '../../assets/report/device/Behind.jpg';
 import Front_LightAngle1 from '../../assets/report/device/Font_LightAngle1.jpg';
import Front_LightAngle2 from '../../assets/report/device/Font_LightAngle2.jpg';
import Front_LightAngle3 from '../../assets/report/device/Font_LightAngle3.jpg';
import Front_LightAngle4 from '../../assets/report/device/Font_LightAngle4.jpg';
import Front_LightAngle5 from '../../assets/report/device/Font_LightAngle5.jpg';
import Front_LightAngle6 from '../../assets/report/device/Font_LightAngle6.jpg';
import Front_LightAngle7 from '../../assets/report/device/Font_LightAngle7.jpg';
import Front_LightAngle8 from '../../assets/report/device/Font_LightAngle8.jpg';
import Front from '../../assets/report/device/Front.jpg';


function Report() { 
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleScreenChange = (isFullScreen) => {
    setIsFullscreen(isFullScreen);
  };

  const renderImage = (image) => (       
      <Magnifier
        src={image.original}
        mgShape="circle"
        mgShowOverflow={false}
        mgWidth={200}
        mgHeight={200}
        zoomFactor={1.5}
        alt="Device image"
        style={{ width: "100%" }}
      />    
  );

  //Carousel Images
  const CarouselImages = [
    { original: Back, thumbnail: Back },
    { original: Behind, thumbnail: Behind },
    { original: Front_LightAngle1, thumbnail: Front_LightAngle1 },
    { original: Front_LightAngle2, thumbnail: Front_LightAngle2 },
    { original: Front_LightAngle3, thumbnail: Front_LightAngle3 },
    { original: Front_LightAngle4, thumbnail: Front_LightAngle4 },
    { original: Front_LightAngle5, thumbnail: Front_LightAngle5 },
    { original: Front_LightAngle6, thumbnail: Front_LightAngle6 },
    { original: Front_LightAngle7, thumbnail: Front_LightAngle7 },
    { original: Front_LightAngle8, thumbnail: Front_LightAngle8 },
    { original: Front, thumbnail: Front },
  ];
  
    return (
        <div className="App">
          <header className="App-header">
            <div className='container'>
              <div className='row d-flex align-items-center'>
                <div className='col-md-6'>
                  <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={logo} className="App-logo" alt="logo" />
                  </a>
                </div>
                <div className='col-md-6'>
                  <p>DEVICE INSPECTION <span>IN PROGRESS</span></p>
                </div>
              </div>
            </div>
          </header>
          <section className='sec-1'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className='col-md-6'>
                  <div className="f-left">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <ul>
                          <li>Report ID</li>
                          <li>Report Date</li>
                          <li>Client Date</li>
                          <li>Chain of Custody</li>
                        </ul>
                      </div>
                      <div className="col-md-5">
                        <ul>
                          <li>PMR09877688778676</li>
                          <li>6/29/20</li>
                          <li>PM008565</li>
                          <li>PM008565</li>
                        </ul>
                      </div>
                      <div className="col-md-3">
                        <img src={qr} className="img-fluid" alt="qr" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li><strong>TRANSACTION</strong></li>
                        <li>Date: Aug 7, 2024</li>
                        <li>Transaction ID: T0</li>
                        <li>T0JE1RJ2E</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li><strong>STORE LOCATION</strong></li>
                        <li>QDN- Katy</li>
                        <li>mostly here</li>
                        <li>555-555-5555</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sec-2">
            <div className="container">
              <div className="row pt-3">
                <div className="col-md-4 px-3">
                  <div className="card-inner">
                    <img src={device} className="img-fuild" alt="device" />
                    <h2>DEVICE INFORMATION</h2>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="text-start">
                          <li>IMEI</li>
                          <li>Memory</li>
                          <li>Color</li>
                          <li>Model</li>
                          <li>Serial #</li>
                          <li>Model #</li>
                          <li>Manufacturer</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="text-start">
                          <li>8765439876</li>
                          <li>56GB</li>
                          <li>Space Gray</li>
                          <li>iPhone X</li>
                          <li>F6543NP8776MC</li>
                          <li>MG9N6LL/A</li>
                          <li>Apple</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 px-3">
                  <div className="card-inner">
                    <img src={network} className="img-fuild" alt="network" />
                    <h2>NETWORK COMPATABILITY</h2>
                    <ul className="text-center">
                      <li>AT&T</li>
                      <li>Sprint</li>
                      <li>Cricket</li>
                      <li>Verizon</li>
                      <li>T-Mobile</li>
                      <li>Metro PCS</li>
                      <li>Boost Mobile</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 px-3">
                  <div className="card-inner">
                    <img src={health} className="img-fuild" alt="health" />
                    <h2>DEVICE BATTERY HEALTH</h2>
                    <ul className="text-start">
                      <li><i className="fa-regular fa-circle-check"></i> Passed: Tested Acceptable</li>
                      <li><i className="fa-solid fa-circle"></i> Caution: Tested Warning</li>
                      <li><i className="fa-solid fa-ban"></i> Failed: Tested Unacceptable</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
    
          {/* Images */}
          <section className="sec-3">
              <div className="container">
                <div className="row pt-3">
                  <div className="img-box">                      
                    <ImageGallery
                       items={CarouselImages.map(image => ({
                      ...image,
                      renderItem: () => renderImage(image),
                      }))}
                        showThumbnails={!isFullscreen}
                        showFullscreenButton={true}
                        onScreenChange={handleScreenChange}
                      />

                  </div>
                </div>
              </div>
               </section> 
  
    
          <section className="sec-4">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-4 px-3">
                  <div className="card-inner">
                    <h2>SOFTWARE & DIAGNOSTICS</h2>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="text-start">
                          <li>OS</li>
                          <li>OS Version</li>
                          <li>Firmware</li>
                          <li>Rooted</li>
                          <li>Report UUID</li>
                          <li>Device ID</li>
                          <li>Device Date</li>
                          <li>Invoice</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="text-end">
                          <li>iOS</li>
                          <li>10.3.2</li>
                          <li>5.62.00</li>
                          <li>No</li>
                          <li>U76543</li>
                          <li>E651765F7J97/A</li>
                          <li>6/29/20</li>
                          <li>Lot3</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 px-3">
                  <div className="card-inner">
                    <h2>DEVICE DATA & SECURITY</h2>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="text-start">
                          <li>Erasure Type</li>
                          <li>Erasure Confirmation</li>
                          <li>Erasure Date</li>
                          <li>Blacklisted</li>
                          <li>Lost or Stolen</li>
                          <li>FMIP</li>
                          <li>ESN</li>
                          <li>Initial Carrier</li>
                          <li>SIMlock</li>
                          <li>Locked Carrier</li>
                          <li>MDM Lock</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="text-end">
                          <li>BANDS</li>
                          <li>10.3.2</li>
                          <li>5.62.00</li>
                          <li>No</li>
                          <li>U76543</li>
                          <li>E651765F7J97/A</li>
                          <li>6/29/20</li>
                          <li>Lot3</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 px-3">
                  <div className="card-inner">
                    <h2>BANDS<br /><br /></h2>
                    <ul className="text-center">
                      <li>LTE</li>
                      <li>1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 26, 34</li>
                      <li>TD-LTE</li>
                      <li>-</li>
                      <li>FDD-LTE</li>
                      <li>-</li>
                      <li>TD-SCDMA</li>
                      <li>-</li>
                      <li>CDMA EV-DO</li>
                      <li>-</li>
                      <li>UMTS/HSP A+/DC-HSDPA</li>
                      <li>850,900, 1700/21, 1900, 21</li>
                      <li>GSM/EDGE</li>
                      <li>850, 900, 1800, 1900 MHz</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row py-5">
                <div className="col-md-12 px-3">
                  <div className="card-inner px-5">
                    <h2>HARDWARE INSPECTION</h2>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="text-start">
                          <li><i class="fa-regular fa-circle-check"></i> Accelerometer</li>
                          <li><i class="fa-regular fa-circle-check"></i> Bluetooth</li>
                          <li><i class="fa-solid fa-ban"></i> Digitizer</li>
                          <li><i class="fa-regular fa-circle-check"></i> Earpiece</li>
                          <li><i class="fa-regular fa-circle-check"></i> Fingerprint Sensor</li>
                          <li><i class="fa-regular fa-circle-check"></i> Flash</li>
                          <li><i class="fa-regular fa-circle-check"></i> Flip Switch</li>
                          <li><i class="fa-regular fa-circle-check"></i> Front Camera</li>
                          <li><i class="fa-regular fa-circle-check"></i> Front Camera Quality</li>
                          <li><i class="fa-regular fa-circle-check"></i> Front Microphone</li>
                          <li><i class="fa-regular fa-circle-check"></i> Glass Condition</li>
                          <li><i class="fa-regular fa-circle-check"></i> Gyroscope</li>
                          <li><i class="fa-regular fa-circle-check"></i> Home Button</li>
                          <li><i class="fa-regular fa-circle-check"></i> LCD</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="text-start">
                          <li><i class="fa-regular fa-circle-check"></i> Loud Speaker</li>
                          <li><i class="fa-regular fa-circle-check"></i> Microphone</li>
                          <li><i class="fa-regular fa-circle-check"></i> Power Button</li>
                          <li><i class="fa-regular fa-circle-check"></i> Proximity Sensor</li>
                          <li><i class="fa-regular fa-circle-check"></i> Rear Camera</li>
                          <li><i class="fa-regular fa-circle-check"></i> Rear Camera Quality</li>
                          <li><i class="fa-regular fa-circle-check"></i> Screen Rotation</li>
                          <li><i class="fa-regular fa-circle-check"></i> SIM Reader</li>
                          <li><i class="fa-regular fa-circle-check"></i> Vibration</li>
                          <li><i class="fa-regular fa-circle-check"></i> Video Microphone</li>
                          <li><i class="fa-regular fa-circle-check"></i> Volume Down Button</li>
                          <li><i class="fa-regular fa-circle-check"></i> Volume Up Button</li>
                          <li><i class="fa-regular fa-circle-check"></i> WiFi</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer>
            <div className="container">
              <div className="row py-5 align-items-center">
              <div className="col-md-8 text-start">
                  <img src={logo} className="img-fluid flogo" alt="fogo" />
                  {/* <div className="socials">
                    <img src={goog} className="img-fluid" alt="goog" />
                    <img src={fac} className="img-fluid" alt="fac" />
                    <img src={lin} className="img-fluid" alt="lin" />
                    <img src={pin} className="img-fluid" alt="pin" />
                    <img src={ins} className="img-fluid" alt="ins" />
                  </div> */}
                </div>
              <div className="col-md-4">
                  <h3>For Queries: </h3>
                  <ul>
                    <li>
                      <img src={a} className="img-fluid" alt="a" /> 
                      <a href="mailto:info@GearVu.com">info@GearVu.com</a>
                    </li>
                    <li>
                      <img src={mob} className="img-fluid" alt="mob" /> 
                      <a href="tel:18552168091">1.855.216.8091</a>
                    </li>
                    <li>
                      <img src={globe} className="img-fluid" alt="globe" /> 
                      <a href="https://GearVu.com" target="_blank" rel="noopener noreferrer">GearVu.com</a>
                    </li>
                    <li>
                      <img src={lin} className="img-fluid" alt="lin" /> 
                      <a href="https://www.linkedin.com/company/GearVu" target="_blank" rel="noopener noreferrer">GearVu</a>
                    </li>
                  </ul>
                </div>
    
               
              </div>
            </div>
          </footer>
          <div className="footer-bottom">
            <p>©2024, GearVu®  All rights reserved.</p>
          </div>
        </div>
      );
    }
    
    

export default Report;
